import { createRouter, createWebHistory } from "vue-router"

import MainIndex from "@/components/MainIndex";

import PhoneNumberOrder from "@/components/PhoneNumberOrder";
import OrderSearch from "@/components/OrderSearch";
import ChangePriceIndex from "@/components/ChangePriceIndex";
import OrderCustomize from "@/components/OrderCustomize";
import LinkIndex from "@/components/LinkIndex";
import PhoneNumberDetails from "@/components/PhoneNumberDetails";
import WxPay from "@/components/WxPay";
import WxPayResult from "@/components/WxPayResult";
import FangZha from "@/components/FangZha";
import JiangSuFangZha from "@/components/JiangSuFangZha";




const routes = [

    {
        path: "/",
        component:MainIndex,
        meta:{
            title: '手机靓号-可私人定制'
        }
    },
    {
        path: "/rules",
        component:FangZha,
        meta:{
            title: ''
        }
    },
    {
        path: "/jiangsu-rules",
        component: JiangSuFangZha,
        meta:{
            title: ''
        }
    },
    {
        path: "/link",
        component:LinkIndex,
        meta:{
            title: '特惠套餐免费送卡'
        }
    },

    {
        path: "/details/:source",
        component:PhoneNumberDetails,
        meta:{
            title: '号码详情'
        }
    },
    {
        path: "/order/:source",
        component:PhoneNumberOrder,
        meta:{
            title: '手机号码预约'
        }
    },
    {
        path: "/search/:source",
        component:OrderSearch,
        meta:{
            title: '订单查询'
        }
    },
    {
        path: "/change/:source",
        component:ChangePriceIndex,
        meta:{
            title: '改价专区'
        }
    },

    {
        path: "/customize/:source",
        component:OrderCustomize,
        meta:{
            title: '个性定制'
        }
    },
    {
        path: "/WxPay/:source/:tradeNo",
        component:WxPay,
        meta:{
            title: '微信支付'
        }
    },
    {
        path: "/WxPayResult/:source/:tradeNo",
        component:WxPayResult,
        meta:{
            title: '微信支付返回结果'
        }
    },
    {
        path: "/:source",
        component:MainIndex,
        meta:{
            title: '手机靓号-可私人定制'
        }
    }


];

const router = createRouter({
    history: createWebHistory('/'),
    routes
});

export default router;