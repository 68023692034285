<template>
	<div>
		<van-image :src="require('../assets/images/jiangsufangzha/1.jpg')" style="display: block"></van-image>
		<van-image :src="require('../assets/images/jiangsufangzha/2.jpg')" style="display: block"></van-image>
		<van-image :src="require('../assets/images/jiangsufangzha/jihuo_liucheng.jpg')" style="display: block"></van-image>
		<van-image :src="require('../assets/images/jiangsufangzha/3.png')" style="display: block"></van-image>
		<van-image :src="require('../assets/images/jiangsufangzha/4.jpg')" style="display: block"></van-image>
		<van-image :src="require('../assets/images/jiangsufangzha/5.jpg')" style="display: block"></van-image>
		<van-image :src="require('../assets/images/jiangsufangzha/6.jpg')" style="display: block"></van-image>
		<van-image :src="require('../assets/images/jiangsufangzha/7.jpg')" style="display: block"></van-image>
	</div>
</template>

<script>
export default {
    name: "FangZha"
}
</script>

<style scoped>

</style>